import React from "react"
import "./media-kit.scss"

const MediaKit = props => {
  const { cardName, cardDescription, text, links } = props?.details || {}
  return (
    <section className="media-kit-section">
      <div className="logo-wrapper">
        <div className="light-logo">
          <img src="https://media.graphassets.com/o2XT5xjWSBSWyDowTbik" />
        </div>
        <div className="dark-logo">
          <img src="https://media.graphassets.com/AVzBEQQSRbKRQYtX2KFf" />
        </div>
        <div className="short-logo">
          <img src="https://media.graphassets.com/P0gUbvStTLC1U5YFRPKo" />
        </div>
      </div>
      <div className="info-section">
        <p>{cardDescription}</p>
        <div className="download-section">
          <div className="kit-name">{cardName}</div>
          <a href={links?.[0]} target="_blank" className="btn btn-secondary">
            {text?.[1]}
          </a>
        </div>
      </div>
    </section>
  )
}

export default MediaKit
